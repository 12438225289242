export const videoFolders = [
    {
        company: "Web Projects",
        show: true,
        heading1: "Web Projects",
        left: '2%',
        top: '8%',
    },
    {
        company: "More Projects",
        show: false,
        heading1: "More Projects",
        left: '16%',
        top: '10%',
    },
]