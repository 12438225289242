export const jobs = [
    {
        image: require("../assets/jobs_logos/invoke.png"),
        company: "Invoke Media",
        heading1: "WEB DEVELOPER CO-OP",
        heading2: "May 2023 - Sept 2023",
        description: "I implemented web and mobile features on Incrowd, a multi-perspective live-streaming platform",
        show: true,
        top: '25%',
        right: '72%',
    },
    {
        image: require("../assets/jobs_logos/alianza.png"),
        company: "Alianza",
        heading1: "QA CO-OP",
        heading2: "Jan 2023 - May 2023",
        description: "I devised, executed and automated test cases for Alianza’s softphone products, as well as documented bugs and troubleshooted fixes.",
        show: true,
        top: '55%',
        right: '40%',
    },
    {
        image: require("../assets/jobs_logos/cra.png"),
        company: "CRA",
        heading1: "STUDENT IT INFRASTRUCTURE ANALYST",
        heading2: "May 2022 - Sept 2022",
        description: "I delivered front-line service to clients and resolved software and hardware issues through troubleshooting.",
        show: true,
        top: '7%',
        right: '22%',
    },
    {
        image: require("../assets/jobs_logos/biztech.png"),
        company: "UBC Biztech",
        heading1: "WEB DESIGNER",
        heading2: "June 2022 - present",
        description: "I manage and maintain the main Biztech website, as well as design and build webpages for our events through Webflow.",
        show: false,
        top: '35%',
        right: '40%',
    },
    {
        image: require("../assets/jobs_logos/uxhub.png"),
        company: "UBC UX Hub",
        heading1: "GRAPHIC DESIGNER",
        heading2: "Sept 2022 - present",
        description: "I design social media posts to promote our events and play a key role in the development of a website for our inaugural designation.",
        show: false,
        top: '35%',
        right: '40%',
    },
    {
        image: require("../assets/jobs_logos/nnect.png"),
        company: "NNECT",
        heading1: "VOLUNTEER DEVELOPER",
        heading2: "Feb 2022 - May 2022",
        description: "I helped streamline the process of gathering, sorting and preparing orders for delivery through automation using Google Sheets and Apps Script.",
        show: false,
        top: '35%',
        right: '40%',
    },

]